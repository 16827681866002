
import { mdiVolumeHigh, mdiVolumeOff } from "@mdi/js"
import intersectionObserver from "@/mixins/intersectionObserver"
import media from "@/mixins/media"

export default {
    name: "EinBlickMedia",
    components: {
        ResponsiveVimeo: () => import("@/components/base/video/ResponsiveVimeo"),
    },
    mixins: [intersectionObserver, media],
    props: {
        /** Either video or image object */
        media: {
            type: Object,
            default: null,
        },
        /** Enables blurred layout and sets media to be contained in it */
        enableLayout: {
            type: Boolean,
            default: true,
        },
        /** Duration of the background fade transition in ms */
        transitionDuration: {
            type: Number,
            default: 500,
            validator: (val) => val >= 0,
        },
    },
    data: () => ({
        hasAudio: false,
        videoMuted: true,
    }),
    icons: {
        mdiVolumeHigh,
        mdiVolumeOff,
    },
    computed: {
        transitionStyles() {
            return {
                "transition-duration": `${this.transitionDuration}ms`,
            }
        },
        alt() {
            return this.media?.file_name ?? ""
        },
    },
    methods: {
        onVimeoReady({ hasAudio }) {
            this.hasAudio = hasAudio
            this.observeIntersection(
                this.$refs["video-player"].$el,
                () => {
                    this.$refs["video-player"]?.playVideo()
                },
                () => {
                    this.$refs["video-player"]?.pauseVideo()
                },
                {
                    threshold: 0.4,
                }
            )
        },
    },
}
