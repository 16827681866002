
import media from "@/mixins/media"
import intersection from "@/directives/intersection"

export default {
    name: "EinBlickGallery",
    components: {
        ResponsiveVimeo: () =>
            import("@/components/base/video/ResponsiveVimeo"),
    },
    directives: { intersection },
    mixins: [media],
    props: {
        /** Array of images/videos */
        mediaList: {
            type: Array,
            default: null,
        },
    },
    emits: ["show-more"],
    computed: {
        mediaListEven() {
            return this.mediaList.filter((a, i) => i % 2 === 0)
        },
        mediaListOdd() {
            return this.mediaList.filter((a, i) => i % 2 === 1)
        },
    },
    unmounted() {
        this.unobserveAll()
    },
    methods: {
        getComponentProps(media) {
            if (this.isVideo(media))
                return {
                    ...this.$attrs,
                    forcePlaceholder: this.$speed.isSlow !== false,
                    video: media,
                    muted: true,
                    autoplay: true,
                    loop: true,
                    playsinline: true,
                }
            return {
                ...this.$attrs,
                image: media,
                alt: media.file_name ?? "Beschreibungsbild",
                size: "2",
                fit: "cover",
                loading: "lazy",
            }
        },
    },
}
