let observer = null

export default {
    /* 
    * usage:  v-intersection
    * params: 
    *  class: custom class, default "intersected", 
    *  on: callback function on intersect, 
    *  off: callback function on unintersect,
    *  options: options for the observer
    * Adds custom emit to element: @intersect
    */
    bind: function (el, binding, vNode) {
        const once = binding.modifiers?.once || false

        const className = binding.value?.class || "intersected"

        observer = new IntersectionObserver((ents) => {
            ents.forEach((ent) => {
                if (ent.isIntersecting) {
                    if (once && el.classList.contains(className)) return
                    binding.value?.on?.()
                    vNode.data?.on?.intersect?.(true)
                    vNode.componentOptions?.listeners?.intersect?.(true)
                    el.classList.add(className)
                } else {
                    if (once) return
                    el.classList.remove(className)
                    binding.value?.off?.()
                    vNode.data?.on?.intersect?.(false)
                    vNode.componentOptions?.listeners?.intersect?.(false)
                }
            })
        }, binding.value?.options || {})
        observer.observe(el)
    },
    unbind() {
        observer?.disconnect()
    }
}

