
import { EinBlickBaseInfo } from "@/components/einblick"
import { ContactPersonAvatar } from "@/components/business/contactperson"
import { mdiInformationOutline } from "@mdi/js"
import themeable from "@/mixins/themeable"
import { EVENT_APPLY_MODAL_ACTIVATOR_CLICK } from "@/enums/googleAnalytics"
import { APPRENTICESHIP, JOB } from "@/enums/jobtypes"

export default {
    name: "EinBlickPopup",
    components: {
        EinBlickBaseInfo,
        ContactPersonAvatar,
        ApprenticeshipPopupCard: () => import("@/components/job/ApprenticeshipPopupCard"),
        JobPopupCard: () => import("@/components/job/JobPopupCard"),
    },
    mixins: [themeable],
    props: {
        job: {
            type: Object,
            required: true,
        },
        /** Shows badge for the contact person avatar */
        showBadge: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showInfoMenu: false,
        showApplyCard: false,
        apprenticeshipCardStep: 0,
    }),
    icons: { mdiInformationOutline },
    computed: {
        isApprenticeship() {
            return this.job?.type_id === APPRENTICESHIP
        },
        isJob() {
            return this.job?.type_id === JOB
        },
        applyContentClasses() {
            const classes = [
                "einblick-popup-menu",
                "apply-menu",
                "rounded-xl",
                "overflow-visible",
            ]
            const isTextDark = this.themeIsTextDark(
                this.theme.backgroundPrimary
            )
            if (isTextDark) classes.push("elevation-4")
            else classes.push("elevation-3")
            return classes.join(" ")
        },
    },
  watch: {
      showApplyCard(newVal) {
        if (newVal) this.apprenticeshipCardStep = 0
      }
  },
  methods: {
        onApplyModalActivatorClick() {
            this.$gtag.event(EVENT_APPLY_MODAL_ACTIVATOR_CLICK)
            this.showApplyCard = true
        },
        /** Public function - opens info menu */
        openInfo() {
            this.showInfoMenu = true
        },
        /** Public function - opens card */
        openCard() {
            this.showApplyCard = true
        },
    },
}
