
import { CheckboxField } from "@/components/base"

export default {
    name: "Gallery",
    components: { CheckboxField },
    data: () => ({
        selected: [],
        images: [],
    }),
    methods: {
        toggleImage(img) {
            if (this.selected.includes(img)) {
                const index = this.selected.indexOf(img)
                if (index > -1) {
                    this.selected.splice(index, 1) // 2nd parameter means remove one item only
                }
            } else {
                this.selected.push(img)
            }
        },
        /** external API */
        getSelection() {
            return [...this.selected]
        },
        reset() {
            this.selected = []
        },
    },
}
