import { getImage, getOGImage } from "@/helpers/image"
import mapper, {
    mapGraduationPerson,
    graduationMapping,
    graduationCategory,
    mapBusinessResponseTime,
} from "@/helpers/mapping"
import { APPRENTICESHIP, TRAINEESHIP } from "@/enums/jobtypes"
import { getContentBlocks, getFullBaseURL } from "@/helpers"
import { getRoute, getApp } from "@/helpers/context"

const GRADUATION_PERSON = mapper.mapValue(mapGraduationPerson)
const GRADUATION = mapper.mapValue(graduationMapping)
const GRADUATION_CATEGORY = mapper.mapValue(graduationCategory)

export function organization(business, withLocation = false) {
    const baseURL = getFullBaseURL()
    const data = {
        "@context": "http://schema.org",
        "@type": "Organization",
        name: business.short_name || business.display_name || business.name, 
        legalName: business.name,
        alternateName: business.name,
        url: baseURL + getRoute().path,
        logo: baseURL + getImage(business.logo, "2", "jpg").src,
        description: business.about_us ?? "",

    }
    if (withLocation) {
        data.address = postalAddress(business.primary_address)
        data.location = postalAddress(business.primary_address)
    }
    return data
}

export function jobPosting(job) {
    const fullPath = getFullBaseURL() + getRoute().path
    const address = job.business_site?.primary_address ?? {}
    const business = getApp().$businessProvider?.business || job.business
    const result = [
        {
            "@context": "http://schema.org/",
            "@type": "JobPosting",
            baseSalary: monetaryAmount(job),
            datePosted: job.published_at,
            description: getDescription(job),
            directApply: job.use_external_site ? undefined : "True",
            educationRequirements: educationalOccupationalCredential(job.required_graduation),
            employmentType: job.type_id === 2 ? "INTERN" : "FULL_TIME",
            employerOverview: business.about_us ?? "",
            hiringOrganization: organization(job.business_site),
            image: getMetaImage(job),
            jobLocation: {
                "@type": "Place",
                address: postalAddress(address)
            },
            jobStartDate: job.date_of_start,
            name: job.name,
            qualifications: GRADUATION_PERSON(job.required_graduation),
            salaryCurrency: "EUR",
            skills: skills(job),
            title: getNameWithType(job),
            url: fullPath,
            validThrough: job.date_application_deadline || getDeadlinePlaceholder()
        }
    ]
    if (
        job.type_id !== APPRENTICESHIP ||
        job.apprenticeship?.salaries[0] ||
        !job.estimated_salary
    ) {
        return result
    }
    if (!business.is_education_institute) {
        result[0].estimatedSalary = monetaryAmountDistribution(job.estimated_salary)
        result.push(occupation(job, fullPath))
    }
    return result
}


//
// Sub-Structures
//
function postalAddress(address) {
    return {
        "@type": "PostalAddress",
        streetAddress: address.street,
        addressLocality: address.city,
        addressRegion: address.state,
        postalCode: address.postal_code,
        addressCountry: address.country_code,
    }
}

function educationalOccupationalCredential(graduation) {
    if (graduation === "none") return undefined
    return {
        "@type": "EducationalOccupationalCredential",
        credentialCategory: GRADUATION_CATEGORY(graduation) ?? "high school",
        educationalLevel: GRADUATION(graduation) ?? "Kein Schulabschluss",
    }
}

function monetaryAmountDistribution(estimatedSalary) {
    return {
        "@type": "MonetaryAmountDistribution",
        name: "base",
        currency: "EUR",
        duration: "P1M",
        percentile10: estimatedSalary.percentile10,
        percentile25: estimatedSalary.percentile25,
        median: estimatedSalary.median,
        percentile75: estimatedSalary.percentile75,
        percentile90: estimatedSalary.percentile90,
    }
}

function occupation(job, url) {
    return {
        "@context": "https://schema.org/",
        "@type": "Occupation",
        name: getNameWithType(job),
        mainEntityOfPage: url,
        sampleSize: job.estimated_salary.sampleSize,
        description: getDescription(job),
        estimatedSalary: [monetaryAmountDistribution(job.estimated_salary)],
        occupationLocation: [
            {
                "@type": "City",
                name: job.business.business_site?.address.city,
            },
        ],
    }
}

function monetaryAmount(job) {
    const baseSalary = job.apprenticeship?.salaries[0]
    if (!baseSalary || job.business.is_education_institute) return undefined
    return {
        "@type": "MonetaryAmount",
        currency: "EUR",
        value: {
            "@type": "QuantitativeValue",
            value: baseSalary?.toFixed(2),
            unitText: "MONTH",
        },
    }
}

function skills(job) {
    if (!job.skills) return ""
    return job.skills.map((s) => {
        return {
            "@type": "DefinedTerm",
            name: s.identifier,
            description: s.comment
        }
    })
}


//
// Helpers
//
function getNameWithType(job) {
    const name = `${job.name} (m/w/d)`
    if (job.type_id === APPRENTICESHIP) return "Ausbildung " + name
    if (job.type_id === TRAINEESHIP) return "Praktikum " + name
    return "Job " + name
}

function getDeadlinePlaceholder() {
    const date = new Date()
    date.setDate(date.getDate() + 30)
    return new Intl.DateTimeFormat("en", {
        dateStyle: "medium",
    }).format(date)
}


function getMetaImage(job) {
    if (job.image) return getOGImage(job.image)
    if (job.video?.thumbnail_url)
        return {
            src: job.video.thumbnail_url,
            type: "image/avif",
        }
    return {}
}

function getDescription(job) {
    let desc = `<p>${getNameWithType(job)} bei ${job.business_site.name} am Standort ${job.business_site.address.city
        }</p><br>`

    const contactPerson = job.business_site.public_contact_person,
        responseTime = job.business_site.avg_response_time
    let contactInfo = contactPerson
        ? `<p>Dein Ansprechparter ${contactPerson.gender} ${contactPerson.firstname} ${contactPerson.lastname}</p>\n`
        : ""
    contactInfo += responseTime
        ? `<p>antwortet dir über Oabat in ${mapBusinessResponseTime(
            responseTime
        )}</p>\n\n`
        : contactPerson
            ? "\n"
            : ""

    if (job.content_blocks?.length > 0) {
        for (const { text, headline } of getContentBlocks(
            job.content_blocks
        )) {
            desc += `<p>${headline}</p>${text}\n\n`
        }
        desc += contactInfo
        return desc
    }
    return desc += contactInfo
}



