
import {GoogleButton} from "@/components/base"
import themeable from "@/mixins/themeable"

export default {
  name: "BusinessPageAbout",
  components: {
    GoogleButton,
  },
  mixins: [themeable],
  props: {
    business: {
      type: Object,
      required: true,
    },
    businessSite: {
      type: Object,
      default: null,
    },
  },
  computed: {
    address() {
      return this.businessSite?.primary_address ?? this.business.primary_address
    },
  }
}
