const mappingService = {
    mapGraduation(backendValue) {
        const mapping = {
            none: "Keiner",
            main: "Mittelschulabschluss",
            quali: "Qualifizierender Hauptschulabschluss",
            mittel: "Mittlerer Schulabschluss",
            fos: "Fachabitur",
            abi: "Abitur",
            bachelor: "Bachelor",
            master: "Master",
            diploma: "Diploma",
        }

        return mapping[backendValue || "none"]
    },
    mapGraduationCategory(backendValue) {
        const mapping = {
            main: "high school",
            quali: "high school",
            mittel: "associate degree",
            fos: "bachelor degree",
            abi: "associate degree",
            bachelor: "bachelor degree",
            master: "bachelor degree",
            diploma: "bachelor degree",
        }

        return mapping[backendValue || "none"]
    },
    mapGraduationPerson(backendValue) {
        const mapping = {
            none: "Für jeden frei",
            main: "Hauptschüler",
            quali: "qual. Hauptschüler",
            mittel: "mittlerer Schulabschluss",
            fos: "Fachabiturient",
            abi: "Abiturient",
            bachelor: "Bachelor",
            master: "Master",
            diploma: "Diploma",
        }

        return mapping[backendValue || "none"]
    },

    mapJobType(backendValue) {
        const mapping = {
            1: "Ausbildung",
            2: "Praktikum",
            3: "Job",
        }

        return mapping[backendValue]
    },

    // parameter - job object
    mapJobDurationType({ duration_type, duration, type_id }) {
        if (!duration) return false
        // check if the job is a traineeship
        if (type_id === 2) {
            let mapping = {}
            if (Number(duration) === 1) {
                mapping = {
                    days: "Tag",
                    weeks: "Woche",
                    months: "Monat",
                }
            } else {
                mapping = {
                    days: "Tage",
                    weeks: "Wochen",
                    months: "Monate",
                }
            }
            return `${duration} ${mapping[duration_type]}`
        } else {
            const years = parseInt(duration / 12)
            const months = duration % 12
            let yearPostfix = "Jahre"
            let monthPostfix = "Monate"
            if (years === 1) yearPostfix = "Jahr"
            if (months === 1) monthPostfix = "Monat"
            if (years === 0) {
                return `${months} ${monthPostfix}`
            } else if (months > 0) {
                return `${years} ${yearPostfix}, ${months} ${monthPostfix}`
            } else {
                return `${years} ${yearPostfix}`
            }
        }
    },

    mapGender(backendValue) {
        const mapping = {
            male: "Herr",
            female: "Frau",
        }
        return mapping[backendValue] || backendValue || ""
    },

    businessEmployees(backendValue) {
        const mapping = {
            1: "1 - 25",
            26: "26 - 75",
            76: "76 - 150",
            151: "151 - 250",
            251: "251 - 500",
            501: "> 500",
        }

        return mapping[backendValue]
    },

    mapFileMimeTypes(mime_type) {
        switch (mime_type) {
            case "application/pdf":
                return "PDF"
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "Doc"
            case "image/jpeg":
            case "image/gif":
            case "image/png":
            case "image/webp":
            case "image/svg+xml":
                return "Photo"
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "Excel"
            default:
                return "File"
        }
    },

    mapNotificationsSettingsType(type) {
        switch (type) {
            case "push":
                return "Push-Benachrichtigungen"
            case "mail":
                return "E-Mail Benachrichtigungen"
            default:
                return type
        }
    },

    mapNotificationsSettingsName(name) {
        switch (name) {
            case "chat":
                return "Bei neuen Chat-Nachrichten"
            case "newsletter":
                return "Newsletter"
            case "application":
                return "Updates zu Bewerbungen"
            case "recommendation":
                return "Jobempfehlungen"
            case "system":
                return "Systemmeldungen"
            default:
                return name
        }
    },

    /** Working day is calculated as 9 hours,
     * therefore the following mapping is applied
     * 1 => 1, 2 => 2, 4 => 4, 8 => 8, 12 => 24
     * Rounding down, exceeds the threshold for 10 minutes
     * e.g. 1:05 => 1, 1:14 => 2
     */
    mapBusinessResponseTime(time) {
        const oneHour = 60 * 60
        const tenMins = 10 * 60
        if (time <= tenMins + oneHour) return "< 1"
        if (time <= tenMins + 2 * oneHour) return "< 2"
        if (time <= tenMins + 4 * oneHour) return "< 4"
        if (time <= tenMins + 8 * oneHour) return "< 8"
        if (time <= tenMins + 12 * oneHour) return "< 24"
        return ">24"
    },
}

export default mappingService
