
export default {
    name: "DynamicContent",
    components: {
        ContentBlockList: () =>
            import("@/components/job/dynamic/ContentBlockList"),
    },
    props: {
        content: {
            type: Array,
            required: true,
        },
    },
}
