import { render, staticRenderFns } from "./EinBlickRecommendations.vue?vue&type=template&id=1ba0232c&scoped=true&"
import script from "./EinBlickRecommendations.vue?vue&type=script&lang=js&"
export * from "./EinBlickRecommendations.vue?vue&type=script&lang=js&"
import style0 from "./EinBlickRecommendations.vue?vue&type=style&index=0&id=1ba0232c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba0232c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default,Button: require('/builds/oabat/einblick/web/src/components/oabatify/Button.vue').default})
