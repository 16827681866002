
import LazyHydrate from "vue-lazy-hydration"

export default {
    name: "ContentBlockList",
    components: {
        LazyHydrate,
        ContentBlockItem: () =>
            import("@/components/job/dynamic/ContentBlockItem"),
    },
    model: {
        prop: "data",
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        positionShift: {
            type: Number,
            default: 0,
        },
        currentSectionId: {
            type: Number,
            default: null,
        },
        currentTabId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        items() {
            return this.data.items
        },
    },
}
