
import { Viewable } from "@/components/base/image"
import { EinBlickMedia } from "@/components/einblick"
export default {
    name: "BusinessVerticalGallery",
    components: { Viewable, EinBlickMedia },
    props: {
        business: {
            type: Object,
            required: true,
        },
    },
    computed: {
        images() {
            return this.business.main_images
        },
    },
}
