
import { ContactPersonSection } from "@/components/business/contactperson"
import { BusinessSiteSelect } from "@/components/business"
import { BusinessHeroGallery, BusinessPageAbout, BusinessVerticalGallery } from "@/components/businesspage"
import themeable from "@/mixins/themeable"
import { EVENT_MESSAGE_BUTTON_CLICK } from "~/enums/googleAnalytics"
import { JOB } from "@/enums/jobtypes"

export default {
  name: "BusinessPage",
  components: {
    ContactPersonSection,
    BusinessHeroGallery,
    BusinessPageAbout,
    BusinessVerticalGallery,
    BusinessSiteSelect,
  },
  mixins: [themeable],
  props: {
    business: {
      type: Object,
      required: true,
    },
    businessSite: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hasRegularJobs() {
      return this.business?.jobs?.some(job => job.type_id === JOB)
    },
    headline() {
      return this.hasRegularJobs  ? "Karriere bei" : "Ausbildung bei"
    },
  },
  methods: {
    onChat() {
      this.$gtag.event(EVENT_MESSAGE_BUTTON_CLICK)
      this.$chatForm.open("ChatMessageForm", {
        payload: { },
      })
    },
  }
}
