import { computed, inject, provide, reactive } from "@nuxtjs/composition-api"
import { DEFAULT_THEME_LIGHT, DEFAULT_THEME_DARK } from "@/helpers/themesHelper"

/** Inject the oaTheme object, if it doesn't exist, then generates oaTheme object with default colors.
 *  Provides the oaTheme theme further (either the inherited one or generated one).
 * */
export default {
    setup() {
        const oaThemeProvided = inject("oaTheme", null)
        const oaThemeCurrentColors = reactive({ ...DEFAULT_THEME_LIGHT })
        const oaThemeDefaultColors = reactive({ ...DEFAULT_THEME_LIGHT })
        const oaThemeDefaultColorsDark = reactive({ ...DEFAULT_THEME_DARK })
        /** Set up the theme from the outside, i.e. set of colors
         *  @param {object} theme colors
         *  @param {string} theme.nav background color for nav bar
         *  @param {string} theme.backgroundPrimary background color for a layout (primary)
         *  @param {string} theme.backgroundSecondary background color for a layout (secondary)
         *  @param {string} theme.backgroundTertiary background color for a layout (tertiary, e.g. pop-ups, dialogs etc)
         *  @param {array} theme.headline array of 2 colors for a gradient headline (text-only)
         *  @param {string, array} theme.buttonPrimary background color(s) for primary buttons
         *  @param {string, array} theme.buttonSecondary background color(s) for secondary buttons
         * */
        function setTheme({
            nav,
            backgroundPrimary,
            backgroundSecondary,
            backgroundTertiary,
            headline,
            buttonPrimary,
            buttonSecondary,
            accentText,
            tags,
        }) {
            oaThemeCurrentColors.nav = nav
            oaThemeCurrentColors.backgroundPrimary = backgroundPrimary
            oaThemeCurrentColors.backgroundSecondary = backgroundSecondary
            oaThemeCurrentColors.backgroundTertiary = backgroundTertiary
            oaThemeCurrentColors.headline = headline
            oaThemeCurrentColors.buttonPrimary = buttonPrimary
            oaThemeCurrentColors.buttonSecondary = buttonSecondary
            oaThemeCurrentColors.accentText = accentText
            oaThemeCurrentColors.tags = tags
        }
        function setThemeDefault() {
            setTheme(oaThemeDefaultColors)
        }
        function setThemeDefaultDark() {
            setTheme(oaThemeDefaultColorsDark)
        }
        /** Determine if text should be dark for a given background color */
        function isDark(backgroundColors, rW, gW, bW) {
            if (typeof backgroundColors === "string")
                backgroundColors = [backgroundColors]
            const thresholds = backgroundColors.map((color) => {
                const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
                    .exec(color)
                    .map((el) => parseInt(el, 16))
                return (
                    components[1] * rW + components[2] * gW + components[3] * bW
                )
            })
            return (
                thresholds.reduce((a, b) => a + b, 0) / thresholds.length > 130
            )
        }
        /** Generate gradient background */
        function genGradient(backgroundColors) {
            let gradient = "linear-gradient(to bottom right"
            backgroundColors.forEach((color) => {
                gradient += ", " + color
            })
            gradient += ")"
            return gradient
        }

        function genTheme(colors) {
            const theme = {}
            Object.keys(colors).forEach((key) => {
                const value = colors[key]
                if (key === "headline") {
                    theme[key] = {
                        text: {
                            "background-image": `linear-gradient(to bottom right, ${value[0]}, ${value[1]})`,
                            "-webkit-background-clip": "text",
                            "-webkit-text-fill-color": "transparent",
                        },
                        color: value,
                    }
                } else if (key === "accentText") {
                    // hacky way to add accent colors
                    const accent = value
                    if (!accent) {
                        theme[key] = {
                            text: theme.backgroundPrimary.text,
                            color: theme.backgroundPrimary.text.color,
                        }
                    } else {
                        theme[key] = {
                            text: {
                                color: accent,
                            },
                            color: accent,
                        }
                    }
                } else if (key === "tags") {
                    theme[key] = colors[key]
                } else if (key !== "config" && value) {
                    const isTextDark = isDark(value, 0.299, 0.587, 0.114)
                    let background = {}
                    if (typeof value === "string")
                        background = { "background-color": value }
                    else if (Array.isArray(value))
                        background = {
                            "background-image": genGradient(value),
                        }
                    theme[key] = {
                        styles: {
                            ...background,
                            color: isTextDark ? "#000000" : "#ffffff",
                        },
                        background: {
                            ...background,
                        },
                        text: { color: isTextDark ? "#000000" : "#ffffff" },
                        color: value,
                        isTextDark,
                    }
                }
            })
            theme.accentText = theme.accentText || theme.backgroundPrimary.text
            return theme
        }
        const oaThemeCurrent = computed(() => genTheme(oaThemeCurrentColors))
        const oaThemeDefault = computed(() => genTheme(oaThemeDefaultColors))
        const oaThemeDefaultDark = computed(() =>
            genTheme(oaThemeDefaultColorsDark)
        )
        /** oaTheme object
         * @property {object} nav                   - Styles and colors for a nav bar
         * @property {object} backgroundPrimary     - Styles and colors for a layout (primary)
         * @property {object} backgroundSecondary   - Styles and colors for a layout (secondary)
         * @property {object} backgroundTertiary    - Styles and colors for a layout (tertiary)
         * @property {object} headline              - Styles and colors for gradient headlines
         * @property {object} buttonPrimary         - Styles and colors for primary buttons
         * @property {object} buttonSecondary       - Styles and colors for secondary buttons
         * */
        const oaTheme = oaThemeProvided || oaThemeCurrent
        provide("oaTheme", oaTheme)

        return {
            oaTheme,
            oaThemeCurrent,
            oaThemeDefault,
            oaThemeDefaultDark,
            oaThemeDefaultColors,
            oaThemeDefaultColorsDark,
            setTheme,
            setThemeDefault,
            setThemeDefaultDark,
            genTheme,
        }
    },
}
