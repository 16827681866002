
export default {
    name: "Sprite",
    props: {
        value: {
            type: Number,
            default: 0,
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        src: {
            required: true,
            type: String,
        },
    },
    computed: {
        style() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
                backgroundImage: `url(${this.src})`,
                backgroundPosition: `-${this.value * this.width}px 0px`,
            }
        },
    },
}
