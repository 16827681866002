import { render, staticRenderFns } from "./BusinessHeroGallery.vue?vue&type=template&id=3b7ca9c5&scoped=true&"
import script from "./BusinessHeroGallery.vue?vue&type=script&lang=js&"
export * from "./BusinessHeroGallery.vue?vue&type=script&lang=js&"
import style0 from "./BusinessHeroGallery.vue?vue&type=style&index=0&id=3b7ca9c5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7ca9c5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default})
