
import { mdiChevronUp } from "@mdi/js"
import CardGridDynamic from "@/components/search/CardGridDynamic"
import jobFeatures from "@/mixins/jobFeatures"
import themes from "@/mixins/themes"

export default {
    name: "EinBlickRecommendations",
    components: {
        CardGridDynamic,
    },
    mixins: [themes, jobFeatures],
    props: {
        /** Recommendations */
        recommendations: {
            type: Object,
            required: true,
        },
        /** Enables desktop layout */
        desktop: {
            type: Boolean,
            default: false,
        },
        /** Ausbildung: 1, Praktikum: 2, Job: 3 */
        jobType: {
            type: Number,
            default: 3,
        },
        businessName: {
            type: String,
            default: "",
        },
    },
    emits: ["scroll-top"],
    icons: {
        mdiChevronUp,
    },
    computed: {
        titleStringDesktop() {
            const infix = this.featureOwnJobs ? `bei ${this.businessName} ` : ""
            const infixJob = this.featureOwnJobs
                ? `, die ${this.businessName} anbietet`
                : ""
            return this.jobType === 1
                ? `Weitere Ausbildungsberufe, die du ${infix}erlernen kannst`
                : this.jobType === 2
                ? `Weitere Berufe, die du ${infix}ausprobieren kannst`
                : `Weiter offene Stellen${infixJob}`
        },
        titleStringMobile() {
            const postfix = this.featureOwnJobs
                ? ` bei ${this.businessName}`
                : ""
            return this.jobType === 1
                ? `Weitere Ausbildungsberufe${postfix}`
                : this.jobType === 2
                ? `Weitere Berufe${postfix}`
                : `Weitere offene Stellen${postfix}`
        },
    },
}
