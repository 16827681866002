
import themeable from "@/mixins/themeable"
import { DEFAULT_THEME_LIGHT } from "@/helpers/themesHelper"

export default {
    name: "EinBlickTags",
    mixins: [themeable],
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        // theme contains 5 colors, if more tags are available, we need to repeat the colors
        tagColors() {
            const themeTagColors =
                this.theme?.tags || DEFAULT_THEME_LIGHT.tags
            return Array(Math.ceil(this.tags.length / themeTagColors.length))
                .fill(themeTagColors)
                .flat()
        },
    },
}
