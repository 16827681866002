// Don't use custom class objects inside

const SECTION_TEMPLATE_1 = {
    id: 1,
    backgroundClips: [
        {
            color: "backgroundPrimary",
            clip: null,
            text: true,
        },
    ],
    components: [
        {
            name: "JobFloatingMediaV3",
            alignment: "full",
            spacerTop: "32px",
        },
        {
            name: "header",
            alignment: "left",
            spacerTop: "56px",
        },
        {
            name: "details",
            alignment: "center",
            spacerTop: "24px",
            spacerBottom: "48px",
        },
        {
            name: "JobGalleryV3",
            alignment: "center",
            spacerBottom: "24px",
        },
    ],
}

const SECTION_TEMPLATE_2 = {
    id: 2,
    backgroundClips: [
        {
            color: "backgroundPrimary",
            clip: null,
            text: false,
        },
        {
            color: "backgroundSecondary",
            clip: "polygon(0px 10vh, 100% min(5vh, 90px), 100% 100%, 0% 100%)",
            text: true,
        },
    ],
    components: [
        {
            name: "JobFloatingMediaV3",
            alignment: "center",
            spacerTop: "16px",
            spacerBottom: "-6vh",
        },
        {
            spacerTop: "10vh",
            name: "header",
            alignment: "right",
        },
        {
            name: "JobGalleryV3",
            alignment: "right",
            spacerTop: "32px",
        },
        {
            name: "details",
            alignment: "right",
            spacerTop: "36px",
            spacerBottom: "36px",
        },
        {
            name: "JobFloatingMediaV3",
            alignment: "full",
            spacerBottom: "24px",
        },
    ],
}

const SECTION_TEMPLATE_3 = {
    id: 3,
    backgroundClips: [
        {
            color: "backgroundPrimary",
            clip: null,
            text: true,
        },
    ],
    components: [
        {
            name: "header",
            alignment: "left",
            spacerTop: "64px",
        },
        {
            name: "JobSlideShowV3",
            spacerTop: "32px",
        },
        {
            name: "details",
            alignment: "center",
            spacerTop: "56px",
            spacerBottom: "24px",
        },
    ],
}

const SECTION_TEMPLATE_4 = {
    id: 4,
    backgroundClips: [
        {
            color: "backgroundPrimary",
            clip: null,
            text: true,
        },
        {
            color: "backgroundSecondary",
            clip: "polygon(0% calc(100% - 20vh), 100% calc(100% - 10vh), 100% 100%, 0% 100%)",
        },
    ],
    components: [
        {
            name: "JobGalleryV3",
            alignment: "center",
            spacerTop: "32px",
        },
        {
            name: "header",
            alignment: "center",
            spacerTop: "56px",
        },
        {
            name: "details",
            alignment: "center",
            spacerTop: "16px",
            spacerBottom: "20vh",
        },
        {
            name: "JobFloatingMediaV3",
            alignment: "center",
            spacerTop: "-15vh",
            spacerBottom: "24px",
        },
    ],
}

export const SECTION_TEMPLATES = [
    SECTION_TEMPLATE_1,
    SECTION_TEMPLATE_2,
    SECTION_TEMPLATE_3,
    SECTION_TEMPLATE_4,
]

export function getTemplateCopy(id) {
    const selectedTemplate =
        SECTION_TEMPLATES.find((t) => t.id === id) ?? SECTION_TEMPLATES[0]
    return JSON.parse(JSON.stringify(selectedTemplate))
}
