
import {
    EinBlickOverview,
    EinBlickPopup,
    EinBlickFloatingMedia,
} from "@/components/einblick"
import { BusinessHeader } from "@/components/business"
import { ShareSpeedModal } from "@/components/base"
import { DynamicContent } from "@/components/job/dynamic"
import { routeToBusinessSite } from "@/helpers/routingEinBlick"

import { ContactPersonSection } from "@/components/business/contactperson"
import { SECTION_TEMPLATES } from "@/helpers/sectionTemplates"
import { mdiChevronLeft, mdiClose } from "@mdi/js"
import intersection from "@/directives/intersection"
import {
    EVENT_APPLY_BUTTON_CLICK,
    EVENT_MESSAGE_BUTTON_CLICK,
} from "@/enums/googleAnalytics"
import { APPRENTICESHIP } from "@/enums/jobtypes"

export default {
    name: "EinBlick",
    components: {
        EinBlickPopup,
        EinBlickOverview,
        BusinessHeader,
        ContactPersonSection,
        ShareSpeedModal,
        DynamicContent,
        EinBlickFloatingMedia,
        JobApprenticeshipDatePicker: () =>
          import("@/components/job/JobApprenticeshipDatePicker"),
        JobCardFlat: () => import("@/components/job/JobCardFlat"),
    },
    directives: { intersection },
    props: {
        /** Job object */
        job: {
            type: Object,
            required: true,
        },
    },
    routes: {
        routeToBusinessSite,
    },
    data: () => ({
        // Helps to hide/display share teilen button on mobile scroll
        lastScroll: 0,
        hideShareButton: false,
        showPopupBadge: false,
        hasIntersected: false,
        // Media modal
        disablePopup: false,
    }),
    icons: {
        mdiChevronLeft,
        mdiClose,
    },
    computed: {
        isApprenticeship() {
          return this.job?.type_id === APPRENTICESHIP
        },
        titleMedia() {
            return this.job.video ?? this.job.image ?? null
        },
        blockDataItems() {
            let contentDataItems = this.job.content_blocks?.at(0)?.data.items
            if (!contentDataItems) return []
            contentDataItems = JSON.parse(JSON.stringify(contentDataItems))
            contentDataItems.forEach((item, idx) => {
                if (!item.data.config) {
                    item.data.config =
                        SECTION_TEMPLATES[idx % SECTION_TEMPLATES.length]
                } else if (!item.data.config.id) {
                    item.data.config.id = (idx % SECTION_TEMPLATES.length) + 1
                }
            })
            return contentDataItems
        },
        dynamicBlocks() {
            return [
                {
                    component: "ContentBlockList",
                    data: {
                        show_gallery: true,
                        items: this.blockDataItems,
                    },
                },
            ]
        },
    },
    methods: {
        onIntersectDynamicContent(value) {
            if (this.hasIntersected) return
            this.showPopupBadge = true
            if (value && this.$breakpoint.xlOnly) {
                this.$refs.popup.openCard()
            }
            this.hasIntersected = true
        },
        onIntersectContactPerson([entry]) {
            if (entry.isIntersecting) {
                this.disablePopup = true
            } else if (entry.boundingClientRect.top > 0) {
                this.disablePopup = false
            }
        },
        onScroll() {
            const currentScroll = window.scrollY
            const diff = currentScroll - this.lastScroll
            if (diff > 20 && currentScroll > 50) {
                this.hideShareButton = true
            } else if (diff < -20 || currentScroll < 50) {
                this.hideShareButton = false
            }
            this.lastScroll = currentScroll
        },
        onChat() {
            this.$gtag.event(EVENT_MESSAGE_BUTTON_CLICK)
            this.$chatForm.open("ChatMessageForm", {
                payload: { jobName: this.job.name },
            })
        },
        onApply() {
            this.$gtag.event(EVENT_APPLY_BUTTON_CLICK)
            this.$chatForm.open("ChatApplyForm", { job: this.job })
        },
    },
}
