export default {
  bind(el, binding) {
    let handler = () => {}, options = null
    if (typeof binding.value === "function")
      handler = binding.value
    else if (typeof binding.value === "object") {
      handler = binding.value.handler
      options = binding.value.options
    }

    el.__wheelHandler__ = (event) => {
      const dx = Math.abs(event.deltaX)
      const dy = Math.abs(event.deltaY)
      if (binding.modifiers?.horizontal && dy / Math.max(dx, 1) > 0.3)
        return
      if (binding.modifiers?.vertical && dx / Math.max(dy, 1) > 0.3)
        return
      if (binding.value.options?.prevent)
        event.preventDefault()
      if (binding.value.options?.stop)
        event.stopPropagation()
      if (binding.modifiers?.horizontal && dx === 1 && dy === 0)
        return
      if (binding.modifiers?.vertical && dx === 0 && dy === 1)
        return
      handler(event)
    }

    el.addEventListener("wheel", el.__wheelHandler__, options)
  },

  unbind(el) {
    el.removeEventListener("wheel", el.__wheelHandler__)
    delete el.__wheelHandler__
  },
}
