
import { BusinessPage } from "@/components/businesspage"
import { getBusinessSiteCombined } from "@/services/business.service"
import { organization } from "@/helpers/structuredData"
import { getBreadcrumb, createDefaultHead } from "@/helpers/head"

export default {
    components: { BusinessPage },
    async fetch() {
        const siteSlug = this.$route.params?.businessSiteCombinedSlug
        const businessSlug = this.business?.slug
        if (!siteSlug) return
        this.businessSite = await this.$api
            .run(getBusinessSiteCombined, businessSlug, siteSlug)
            .request.catch((e) => {
                this.$nuxt.error(e)
            })
    },
    data: () => ({
        businessSite: null,
    }),
    computed: {
        business() {
            return this.$businessProvider?.business
        },
    },
    watch: {
        async $route() {
            await this.$fetch()
        },
    },
    head() {
        const isMainPage = this.business.business_sites.length <= 1
        return createDefaultHead(this.businessSite)
            .addScript(
                getBreadcrumb([
                    {
                        identifier: "business",
                        business: this.business,
                    },
                ])
            )
            .addScript(organization(this.business, isMainPage))
            .build()
    },
}
