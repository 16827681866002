
import mappingService from "@/services/mapping.service"
import themeable from "@/mixins/themeable"
import { ThemeIcon } from "@/components/theme"

import { mdiSchoolOutline, mdiMapMarker, mdiClockTimeFourOutline, mdiCalendarMonth } from "@mdi/js"

export default {
  name: "EinBlickBaseInfo",
  components: { ThemeIcon },
  mixins: [themeable],
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  icons: {
    mdiSchoolOutline,
    mdiMapMarker,
    mdiClockTimeFourOutline,
    mdiCalendarMonth,
  },
  computed: {
    jobTypeName() {
      switch (this.job.type_id) {
        case 1:
          return "Ausbildung"
        case 2:
          return "Praktikum"
        case 3:
          return "Job"
        default:
          console.warn("Error: unrecognizable job type")
          return ""
      }
    },
    graduation() {
      const graduation = mappingService.mapGraduationPerson(
        this.job.required_graduation
      )
      if (graduation === "Für jeden frei") return graduation
      return `mind. ${graduation}`
    },
    duration() {
      if (!this.job.duration) return
      return mappingService.mapJobDurationType(this.job)
    },
    businessAddress() {
      return this.job.business_site?.address
    },
    detailBlocks() {
      const blocks = []
      // graduation
      blocks.push({
        prefix: "Wer",
        text: this.graduation,
        icon: this.icons.mdiSchoolOutline,
      })
      // location
      if (this.businessAddress)
        blocks.push({
          prefix: "Wo",
          text: this.businessAddress.street.concat(
            ", ",
            this.businessAddress.city
          ),
          icon: this.icons.mdiMapMarker,
        })
      // duration
      if (this.duration)
        blocks.push({
          prefix: "Wie lang",
          text: this.duration,
          icon: this.icons.mdiClockTimeFourOutline,
        })
      // starting date
      if (this.isApprenticeship && this.job.date_of_start)
        blocks.push({
          prefix: "Beginn",
          text: this.toDate(this.job.date_of_start),
          icon: this.icons.mdiCalendarMonth,
        })
      return blocks
    },
    textGreyShade() {
      return this.themeIsTextDark(this.theme.backgroundSecondary) ? "grey-6--text" : "grey-2--text"
    },
  },
}
