import { render, staticRenderFns } from "./EinBlickMedia.vue?vue&type=template&id=70499978&scoped=true&"
import script from "./EinBlickMedia.vue?vue&type=script&lang=js&"
export * from "./EinBlickMedia.vue?vue&type=script&lang=js&"
import style0 from "./EinBlickMedia.vue?vue&type=style&index=0&id=70499978&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70499978",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default,Button: require('/builds/oabat/einblick/web/src/components/oabatify/Button.vue').default,TransitionFade: require('/builds/oabat/einblick/web/src/components/oabatify/transition/TransitionFade.vue').default,Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default,OImg: require('/builds/oabat/einblick/web/src/components/oabatify/OImg.vue').default})
