
import { EinBlickTags } from "@/components/einblick"
import themeable from "~/mixins/themeable"

export default {
    name: "EinBlickOverview",
    components: { EinBlickTags },
    mixins: [themeable],
    props: {
        /** Job object */
        job: {
            type: Object,
            required: true,
        },
    },
    computed: {
        jobTypeName() {
            switch (this.job.type_id) {
                case 1:
                    return "Ausbildung"
                case 2:
                    return "Praktikum"
                case 3:
                    return "Job"
                default:
                    console.warn("Error: unrecognizable job type")
                    return ""
            }
        },
        textGreyShade() {
            return this.themeIsTextDark(this.theme.backgroundPrimary)
                ? "grey-6--text"
                : "grey-2--text"
        },
    },
}
