
export default {
    name: "ContentBlockItem",
    components: {
        Section1: () => import("@/components/einblick/sections/v1/Section1"),
        Section2: () => import("@/components/einblick/sections/v1/Section2"),
        Section3: () => import("@/components/einblick/sections/v1/Section3"),
        Section4: () => import("@/components/einblick/sections/v1/Section4"),
    },
    model: {
        prop: "data",
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        headline() {
            return this.data.headline
        },
        text() {
            return this.data.text
        },
        media() {
            if (this.data.media) return this.data.media
            const videos = this.data.videos ?? []
            const images = this.data.images ?? []
            return videos.concat(images)
        },
        config() {
            return this.data.config ?? null
        },
        sectionComponent() {
            // config.id was added to allow for selection of the section type in the job editor.
            // Older jobs exist without ids on their sections.
            // id's are guaranteed to exist here because they get added in EinBlick.vue if they don't exist.
            // Indices count from 1, but need to be in a ring of length 4, but also starting from 1
            return `Section${((this.config?.id - 1) % 4) + 1}`
        },
    },
}
