
import { close } from "@/assets/icons"

export default {
    name: "Viewable",
    data: () => ({
        isViewed: false,
    }),
    icons: {
        close,
    },
    computed: {
        listeners() {
            return {
                "click.native": this.onClick,
            }
        },
    },
    methods: {
        onClick() {
            this.isViewed = !this.isViewed
        },
    },
}
