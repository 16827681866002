
import { mdiVolumeHigh, mdiVolumeOff } from "@mdi/js"
import intersectionObserver from "@/mixins/intersectionObserver"
import themeable from "@/mixins/themeable"
import media from "@/mixins/media"

export default {
    name: "EinBlickFloatingMedia",
    components: {
        ResponsiveVimeo: () =>
            import("@/components/base/video/ResponsiveVimeo"),
    },
    mixins: [themeable, intersectionObserver, media],
    props: {
        /** Either video or image object */
        media: {
            type: Object,
            default: null,
        },
        /** Floating position of the media */
        alignment: {
            type: String,
            default: "full",
            validator: (val) =>
                ["full", "left", "right", "center"].includes(val),
        },
        /** Loads images eager */
        eager: {
            type: Boolean,
            default: false,
        },
        placeholderMaxWidth: {
            type: Number,
            default: undefined,
        },
    },
    emits: ["show-more"],
    data: () => ({
        videoMuted: true,
        showTooltip: false,
    }),
    computed: {
        wrapperClasses() {
            if (this.alignment === "right") return "pl-8"
            else if (this.alignment === "left") return "pr-8"
            else if (this.alignment === "center") return "px-8"
            return ""
        },
        contentStyles() {
            if (this.alignment !== "center") return {}
            if (this.themeIsTextDark(this.theme.backgroundPrimary))
                return {
                    "box-shadow": "0 10px 20px 0 #00295e80 !important",
                }
            return {
                "box-shadow": "0 10px 20px 0 #ffffff40 !important",
            }
        },
    },
    icons: {
        mdiVolumeHigh,
        mdiVolumeOff,
    },
    methods: {
        onVideoReady() {
            this.observeIntersection(
                this.$refs["video-player"].$el,
                () => {
                    this.$refs["video-player"]?.playVideo()
                },
                () => {
                    this.$refs["video-player"]?.pauseVideo()
                },
                {
                    threshold: 0.4,
                }
            )
        },
    },
}
