import { render, staticRenderFns } from "./BusinessPage.vue?vue&type=template&id=43592e04&scoped=true&"
import script from "./BusinessPage.vue?vue&type=script&lang=js&"
export * from "./BusinessPage.vue?vue&type=script&lang=js&"
import style0 from "./BusinessPage.vue?vue&type=style&index=0&id=43592e04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43592e04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default,Container: require('/builds/oabat/einblick/web/src/components/oabatify/Container.vue').default})
