
import { SearchResult } from "@/components/search"

export default {
    name: "BusinessPageJobList",
    components: {
        SearchResult,
    },
    props: {
        jobs: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: [Number, String],
            default: null,
        },
    },
    data: () => ({
        additionalRows: 0,
    }),
    computed: {
        totalRows() {
            if (this.rows === null) return null
            return this.additionalRows + Number(this.rows)
        },
    },
    methods: {
        showMore() {
            this.additionalRows += 3
        },
    },
}
