
import Swiper from "swiper"

import { mdiVolumeHigh, mdiVolumeOff } from "@mdi/js"
import wheel from "@/directives/wheelDirective"
import intersection from "@/directives/intersection"

import themes from "@/mixins/themes"
import media from "@/mixins/media"
import "swiper/swiper.scss"

export default {
    name: "EinBlickSlideShow",
    components: {
        ResponsiveVimeo: () =>
            import("@/components/base/video/ResponsiveVimeo"),
    },
    directives: {
        wheel,
        intersection,
    },
    mixins: [themes, media],
    props: {
        /** Array of images/videos */
        mediaList: {
            type: Array,
            default: null,
        },
    },
    emits: ["show-more"],
    data: () => ({
        swiper: null,
        videoMuted: true,
        // Helpers for horizontal scrolling
        isScrolling: false,
        scrollingTimeout: null,
        prevDeltaX: 0,
        prevDeltaY: 0,
    }),
    icons: {
        mdiVolumeHigh,
        mdiVolumeOff,
    },
    computed: {
        hasDisplayableVideos() {
            return this.mediaList.some((media) =>
                this.isVideoDisplayable(media)
            )
        },
        currentSlide() {
            return this.swiper?.realIndex ?? 0
        },
    },
    mounted() {
        this.swiper = new Swiper(this.$refs.einBlickSlideShowSwiper, {
            slidesPerView: "auto",
            spaceBetween: 0,
            initialSlide: 0,
            resistanceRatio: 0.85,
            longSwipesRatio: 1,
            allowTouchMove: true,
            grabCursor: true,
        })
        // Updates dynamic widths of slides and sets up the correct position
        setTimeout(() => {
            this.swiper.updateSlides()
            this.swiper.slideTo(0)
        }, 500)
    },
    methods: {
        getComponentProps(media, index) {
            if (this.isVideo(media))
                return {
                    ...this.$attrs,
                    video: media,
                       forcePlaceholder: this.$speed.isSlow !== false,
                    muted: this.videoMuted || this.currentSlide !== index,
                    autoplay: true,
                    loop: true,
                    playsinline: true,
                    enableSoundTooltip: true,
                }
            return {
                ...this.$attrs,
                image: media,
                alt: media.file_name ?? "Beschreibungsbild",
                size: "1",
                fit: "contain",
                loading: "lazy",
            }
        },
        onWheel(e) {
            // Surpassing the inertia of the mouse wheel/trackpad
            const dx = Math.abs(e.deltaX),
                dy = Math.abs(e.deltaY)
            const scrollingCondition =
                dx >= this.prevDeltaX || dy >= this.prevDeltaY
            if (scrollingCondition && !this.isScrolling) {
                this.isScrolling = true
                this.prevDeltaX = dx
                this.prevDeltaY = dy
                if (e.deltaX > 0) this.swiper.slideNext()
                else if (e.deltaX < 0) this.swiper.slidePrev()
            } else {
                clearTimeout(this.scrollingTimeout)
            }

            this.scrollingTimeout = setTimeout(() => {
                this.isScrolling = false
                this.prevDeltaX = 0
                this.prevDeltaY = 0
            }, 30)
        },
    },
}
