

import { Viewable } from "@/components/base/image"
import Swiper from "swiper"
import wheel from '@/directives/wheelDirective'
import "swiper/swiper.scss"

export default {
    name: "BusinessHeroGallery",
    components: {
        Viewable,
        EinBlickMedia: () => import("@/components/einblick/EinBlickMedia"),
    },
    directives: {
        wheel: wheel,
    },
    props: {
        business: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        swiper: null,
        // Helpers for horizontal scrolling
        isScrolling: false,
        scrollingTimeout: null,
        prevDeltaX: 0,
        prevDeltaY: 0,
    }),
    computed: {
        images() {
            return [...this.business.main_images, ...this.business.gallery_images]
        },
    },
    async mounted() {
        this.swiper = new Swiper(this.$refs.swiper, {
            slidesPerView: "auto",
            resistanceRatio: 0.85,
            longSwipesRatio: 1,
            allowTouchMove: true,
        })
    },
    methods: {
      onWheel(e) {
        // Surpassing the inertia of the mouse wheel/trackpad
        const dx = Math.abs(e.deltaX),
          dy = Math.abs(e.deltaY)
        const scrollingCondition = dx >= this.prevDeltaX || dy >= this.prevDeltaY
        if (scrollingCondition && !this.isScrolling) {
          this.isScrolling = true
          this.prevDeltaX = dx
          this.prevDeltaY = dy
          if (e.deltaX > 0) this.swiper.slideNext()
          else if (e.deltaX < 0) this.swiper.slidePrev()
        } else {
          clearTimeout(this.scrollingTimeout)
        }

        this.scrollingTimeout = setTimeout(() => {
          this.isScrolling = false
          this.prevDeltaX = 0
          this.prevDeltaY = 0
        }, 30)
      },
    },
}
